















































































import { defineComponent, } from '@nuxtjs/composition-api';
import { useUiHelpers } from '../../../../composables';
import axios from 'axios';

export default defineComponent({
  name: 'CustomerStories',
  data() {
    return {
      storiesData: [],
      isDragging: false,
      cursorPos: [0, 0],
      el: null,
      settings: {
        // centerPadding: '14',
        // variableWidth: true,
        arrows: false,
        dots: false,
        infinite: true,
        // focusOnSelect: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        touchThreshold: 3,
        responsive: [
          {
            breakpoint: 1279,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 768,
            settings: {
              variableWidth: true,
              slidesToShow: 3,
            },
          },
        ],
      },
    };
  },
  async mounted() {
    const options = {
      headers: {
        'Access-Control-Allow-Origin': true,
        'Content-Type': 'application/json',
      },
    };
    const getLangCode = () =>
      this.$i18n.locale === 'ar' ? '&langcode=ar' : '';
    const storiesResponse = await axios.get(
      `https://cms.almarwan.com/api/stories?_format=json${getLangCode()}`,
      options
    );
    if(storiesResponse.data) {
      this.storiesData = storiesResponse.data;
    }
    window.addEventListener('mouseup', this.onMouseUp);
  },
  destroyed() {
    window.removeEventListener('mouseup', this.onMouseUp);
  },
  methods: {
    onMouseDown(ev) {
      this.cursorPos = [ev.pageX, ev.pageY];
      this.isDragging = true;

      window.addEventListener('mousemove', this.onMouseHold);
    },
    onMouseUp(ev) {
      window.removeEventListener('mousemove', this.onMouseHold);
      this.isDragging = false;
    },
    onMouseHold(ev) {
      ev.preventDefault();

      requestAnimationFrame(() => {
        const delta = [
          ev.pageX - this.cursorPos[0],
          ev.pageY - this.cursorPos[1],
        ];

        this.cursorPos = [ev.pageX, ev.pageY];

        if (!this.$refs.el) return;
        // @ts-ignore: Unreachable code error
        this.$refs.el.scrollBy({
          left: -delta[0],
          top: -delta[1],
        });
      });
    },
    getImgSrc(src) {
      return src.startsWith('/') ? src.slice(1) : src;
    },
  },
  setup() {
    const { strippedHtml } = useUiHelpers();

    return {
      strippedHtml,
    };
  },
});
