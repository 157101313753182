










































































































































import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useContext,
  useFetch,
  watch
} from '@nuxtjs/composition-api';
// eslint-disable-next-line import/no-extraneous-dependencies
import Glide from '@glidejs/glide';
import useProduct from '~/modules/catalog/product/composables/useProduct';
import { SortEnum } from '~/modules/GraphQL/types';
import WishlistGuest from '~/components/General/WishlistGuest.vue';
import { useUiHelpers } from '~/composables';
import type { Product } from '~/modules/catalog/product/types';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { useConfigStore } from '~/stores/config';
import useWishlist from '~/modules/wishlist/composables/useWishlist';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import app from '~/serverMiddleware/body-parser';

export default defineComponent({
  name: 'BrandNewArrivals',
  components: { SkeletonLoader, WishlistGuest },
  setup() {
    const { getProductList } = useProduct();
    const { numberWithCommas } = useUiHelpers();
    const { isInWishlist } = useWishlist();
    const { storeConfig } = useConfigStore();
    const { app: { i18n } } = useContext();
    const numberOfInstallments = ref(storeConfig.no_of_installments)
    const products = ref([]);
    let glide = null;
    useFetch(async () => {
      const brandNewArrivals = await getProductList({
        filter: { 
          condition: { eq: '619' },
          promoted_products: {
            match: '0|1|2|3|4|5|6|7|8|9|10'
          }
        },
        pageSize: 10,
        currentPage: 1,
        sort: {
          promoted_products: SortEnum.Asc,
        },
      });
      if (brandNewArrivals?.items?.length) {
        products.value = brandNewArrivals.items?.map((product: Product) => ({
          ...product,
          // @ts-ignore
          isInWishlist: computed(() => isInWishlist({ product })),
        }));
      }
    })
    const getProductLabel = (product) => {
      if (!product.condition) {
        return null
      }

      switch (product.condition) {
        case '621': {
          return 'Used'
        }
        case '620': {
          return 'Like New'
        }
        case '619': {
          return 'New'
        }
        default: {
          return null
        }
      }
    }
    const buyPrice = (product) => product?.price_range?.minimum_price?.regular_price?.value
    const discountPrice = (product) => product && product?.price_range?.minimum_price?.discount?.percent_off
    const getThumbnail = (product: Product) => productGetters.getProductThumbnailImage(product);
    const getThumbnailLabel = (product: Product) => productGetters.getProductThumbnailLabel(product);

    const enginePower = (product) => product.engine_power?.split(' ')[0];
    const initiateGlide = () => {
      glide = new Glide('.glide_new_arrivals', {
        type: 'slider',
        rewind: true,
        rewindDuration: 3000,
        startAt: 0,
        perView: 3,
        bound: true,
        gap: 26,
        direction: i18n.locale === 'ar' ? 'rtl' : 'ltr',
        breakpoints: {
          820: {
            perView: 2.3,
            gap: 16,
            bound: true,
          },
          800: {
            perView: 2,
            gap: 16,
            bound: true,
          },
          480: {
            perView: 1.13,
            gap: 12,
            bound: true,
          }
        }
      });
      glide?.mount();
    }
    onMounted(() => {
      watch(() => products.value, (newVal) => {
        if (glide === null && newVal.length > 0) {
          initiateGlide()
        }
      });
      if (products.value.length > 0) {
        initiateGlide()
      }
    })
    onUnmounted(() => {
      if (glide) {
        glide.destroy();
      }
    })
    return {
      numberWithCommas,
      getProductLabel,
      getThumbnail,
      getThumbnailLabel,
      discountPrice,
      enginePower,
      buyPrice,
      products,
      productGetters,
      numberOfInstallments
    }
  }
});
