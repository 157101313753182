





































































































import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  useContext,
  useFetch,
  watch
} from '@nuxtjs/composition-api';
import Glide from '@glidejs/glide';
import { useContent } from '~/composables';

export default defineComponent({
  name: 'Hero',
  setup() {
    const { loadBlocks } = useContent();
    const { app: { i18n } } = useContext();
    const heroBannerData = ref(null);
    let glide = null;
    let blockData = null;
    useFetch(async () => {
      if (!blockData){
        blockData = await loadBlocks({ identifiers: ['home_page_slider'] });
        if (blockData) {
          heroBannerData.value = JSON.parse(blockData?.[0]?.content)
        }
      }
    });
    const initiateGlide = () => {
      glide = new Glide('.hero_slider', {
        type: 'carousel',
        autoplay: 3000,
        animationDuration: 1000,
        perView: 1,
        direction: i18n.locale === 'ar' ? 'rtl' : 'ltr',

        breakpoints: {
          800: {
            perView: 1
          },
          480: {
            perView: 1
          }
        }
      });
      glide?.mount();
    }
    onUnmounted(() => {
      if (glide) {
        glide.destroy();
      }
    })
    onMounted(() => {
      watch(() => heroBannerData.value, (newVal) => {
        if (glide === null && newVal !== null) {
          initiateGlide()
        }
      });
      if (heroBannerData.value) {
        initiateGlide()
      }
    })
    return {
      heroBannerData
    }
  }
})
