









































































































































import { defineComponent, ref, useFetch, onMounted, useContext, onUnmounted, watch } from '@nuxtjs/composition-api';
import Glide from '@glidejs/glide';
import useProduct from '~/modules/catalog/product/composables/useProduct';
import { SortEnum } from '~/modules/GraphQL/types';
import { useUiHelpers } from '~/composables';
import { useConfigStore } from '~/stores/config';
import WishlistGuest from '~/components/General/WishlistGuest.vue';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import { Product } from '~/modules/catalog/product/types';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import i18n from '~/plugins/i18n';

export default defineComponent({
  name: 'UsedMachineryArrivals',
  components: { SkeletonLoader, WishlistGuest },
  setup() {
    const { getProductList } = useProduct();
    const { numberWithCommas } = useUiHelpers();
    const { app: { i18n }, app } = useContext();
    const { storeConfig } = useConfigStore();
    const numberOfInstallments = ref(storeConfig.no_of_installments)
    const products = ref([]);
    let glide = null;
    useFetch(async () => {
      const usedMachineryArrivals = await getProductList({
        filter: { 
          condition: { in: ['620', '621'] },
          promoted_products: {
            match: '0|1|2|3|4|5|6|7|8|9|10'
          }
        },
        pageSize: 10,
        currentPage: 1,
        sort: {
          promoted_products: SortEnum.Asc,
        },
      });
      if (usedMachineryArrivals?.items?.length) {
        products.value = usedMachineryArrivals.items
      }
    })
    const getProductLabel = (product) => {
      if (!product.condition) {
        return null
      }

      switch (product.condition) {
        case '621': {
          return 'Used'
        }
        case '620': {
          return 'Like New'
        }
        case '619': {
          return 'New'
        }
        default: {
          return null
        }
      }
    }
    const buyPrice = (product) => product?.price_range?.minimum_price?.regular_price?.value
    const buyFinalPrice = (product) => productGetters.getPrice(product).final
    const discountPrice = (product) => product && product?.price_range?.minimum_price?.discount?.percent_off
    const getThumbnail = (product: Product) => productGetters.getProductThumbnailImage(product);
    const getThumbnailLabel = (product: Product) => productGetters.getProductThumbnailLabel(product);
    const enginePower = (product) => product.engine_power?.split(' ')[0];
    const sliderDir = app.i18n.locale == 'ar' ? 'rtl' : 'ltr'
    const initiateGlide = () => {
      glide = new Glide('.glide_used_machinery', {
        type: 'slider',
        rewind: true,
        rewindDuration: 3000,
        startAt: 0,
        perView: 3,
        bound: true,
        direction: sliderDir,
        gap: 32,
        breakpoints: {
          820: {
            perView: 2.3,
            gap: 16,
            bound: true,
          },
          800: {
            perView: 2,
            gap: 16,
            bound: true,
          },
          480: {
            perView: 1.13,
            gap: 12,
            bound: true,
          }
        }
      });
      glide?.mount();
    }
    onMounted(() => {
      watch(() => products.value, (newVal) => {
        if (glide === null && newVal.length > 0) {
          initiateGlide()
        }
      });
      if (products.value.length > 0) {
        initiateGlide()
      }
    })
    onUnmounted(() => {
      if (glide) {
        glide.destroy();
      }
    })
    return {
      buyFinalPrice,
      numberWithCommas,
      getProductLabel,
      getThumbnailLabel,
      getThumbnail,
      discountPrice,
      enginePower,
      buyPrice,
      products,
      productGetters,
      numberOfInstallments
    }
  }
})
