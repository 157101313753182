































































import { defineComponent, useFetch } from '@nuxtjs/composition-api';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import { MetaInfo } from 'vue-meta';
import LazyHydrate from 'vue-lazy-hydration';
import Hero from '../../../components/Header/Hero.vue';
import LatestNews from '../blog/components/LatestNews.vue';
import CustomerStories from '../blog/components/CustomerStories.vue';
import LoadWhenVisible from '../../../components/utils/LoadWhenVisible.vue';
import PopularCategories from './components/PopularCategories.vue';
import BrandNewArrivals from './components/BrandNewArrivals.vue';
import UsedMachineryArrivals from './components/UsedMachineryArrivals.vue';
import { useUiHelpers } from '../../../composables';

export default defineComponent({
  name: 'MainView',
  components: {
    ValueWidgets: () => import('../../components/ValueWidget.vue'),
    TrendingProducts: () => import('../../components/TrendingProducts.vue'),
    OurLocation: () => import('../../components/OurLocation.vue'),
    ServicesSection: () =>
      import('../../views/home/components/ServicesSection.vue'),
    Maintenance: () => import('../../views/home/components/Maintenance.vue'),
    NewsLetter: () => import('../../components/Newsletter.vue'),
    Financing: () => import('../../views/home/components/Financing.vue'),
    Counter: () => import('../../components/Counter.vue'),
    Brands: () => import('../../components/Brands.vue'),
    Faq: () => import('../../views/home/components/Faq.vue'),
    HowItWorks: () => import('../../views/home/components/HowItWorks.vue'),
    OrderQuickly: () => import('../../views/home/components/OrderQuickly.vue'),
    DefaultSection: () =>
      import('../../views/home/components/DefaultSection.vue'),
    OurCustomers: () => import('../../components/OurCustomers.vue'),
    Industries: () => import('../../views/home/components/Industries.vue'),
    Banner: () => import('../../views/home/components/Banner.vue'),
    UsedMachineryArrivals,
    BrandNewArrivals,
    PopularCategories,
    LoadWhenVisible,
    Hero,
    LatestNews,
    CustomerStories,
    LazyHydrate,
  },
  setup() {
    const { addTags } = useCache();
    const { getFullUrl } = useUiHelpers();

    useFetch(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    return {
      getFullUrl,
    };
  },
  head(): MetaInfo {
    const title = this.$t('homeTitle').toString();
    const description = this.$t('Al Marwan is your one-stop shop for new & used heavy equipment & machinery rental and sale in the UAE, GCC, and Middle East').toString();
    return {
      title,
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: title
        },
        {
          hid: 'twitter:title',
          name: 'twitter:title',
          content: title,
        },
        {
          hid: 'og:title',
          name: 'og:title',
          content: title,
        },
        {
          hid: 'description',
          name: 'description',
          content: description,
        },
        {
          hid: 'twitter:description',
          name: 'twitter:description',
          content: description,
        },
        {
          hid: 'og:description',
          name: 'og:description',
          content: description,
        },
        {
          hid: 'twitter:image',
          name: 'twitter:image',
          content: 'https://almarwan.com/favicon.ico',
        },
        {
          hid: 'og:image',
          name: 'og:image',
          content: 'https://almarwan.com/favicon.ico',
        },
        {
          hid: 'url',
          name: 'url',
          content:  this.getFullUrl(this.$route.path),
        },
        {
          hid: 'site_name',
          name: 'site_name',
          content: title,
        },
        {
          hid: 'og:site_name',
          name: 'og:site_name',
          content: title,
        },
        {
          hid: 'apple-mobile-web-app-title',
          name: 'apple-mobile-web-app-title',
          content: title,
        },
        {
          hid: 'og:apple-mobile-web-app-title',
          name: 'og:apple-mobile-web-app-title',
          content: title,
        }
      ],
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'WebPage',
            name: 'Al Marwan Heavy Machinery',
            description: description,
            url: this.getFullUrl(this.$route.path),
            mainEntity: {
              '@type': 'Organization',
              image: 'https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png',
              url: this.getFullUrl(this.$route.path),
              logo: 'https://almarwan.com/_nuxt/icons/icon_512x512.3b9037.png',
              name: 'Al Marwan Heavy Machinery',
              description: description,
              email: 'hello@almarwan.com',
              telephone: '+971-56-543-3222',
              address: {
                '@type': 'PostalAddress',
                streetAddress: 'Industrial Area 15',
                addressLocality: 'Sharjah',
                addressCountry: 'AE',
                addressRegion: 'Sharjah',
                postalCode: '00000',
              },
            },
          },
        },
        {
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'WebSite',
            '@id':this.getFullUrl(this.$route.path)+'#website',
            url: this.getFullUrl(this.$route.path),
            potentialAction: [
              {
                '@type': 'SearchAction',
                target:
                this.$i18n.locale === 'ar'? this.getFullUrl('/ar/c/search?term={search_term}&tag=buy') :this.getFullUrl('/c/search?term={search_term}&tag=buy'),
                'query-input': 'required name=search_term',
              },
              {
                '@type': 'SearchAction',
                target:
                  this.$i18n.locale === 'ar'? this.getFullUrl('ar/c/search?term={search_term}&tag=rent'): this.getFullUrl('/c/search?term={search_term}&tag=rent'),
                'query-input': 'required name=search_term',
              },
            ],
          },
        },
      ],
    };
  },
});
