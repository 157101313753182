




















































































































































































import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { useContent } from "~/composables";
import EquipmentBadge from '~/almarwan/components/EquipmentBadge.vue';

export default defineComponent({
  name: 'PopularCategories',
  components: { EquipmentBadge },
  setup() {
    const { loadBlocks } = useContent();
    const newForSale = ref(null);
    const usedForSale = ref(null);
    const rentalList = ref(null);

    useFetch(async () => {
      const blockData = await loadBlocks({ identifiers: ['new-for-sale', 'used_for_sale', 'rental-list'] });
      newForSale.value = JSON.parse(blockData[0]?.content);
      newForSale.value = newForSale.value?.categories
      usedForSale.value = JSON.parse(blockData[1]?.content);
      usedForSale.value = usedForSale.value?.categories
      rentalList.value = JSON.parse(blockData[2]?.content);
      rentalList.value = rentalList.value?.categories
    });

    return {
      newForSale,
      usedForSale,
      rentalList
    }
  }
})
