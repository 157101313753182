



















































































































import {
  defineComponent,
  ref,
  useContext,
  useFetch,
} from '@nuxtjs/composition-api';
import axios from 'axios';

export default defineComponent({
  data() {
    return {
      isDragging: false,
      isHolding: false,
      cursorPos: [0, 0],
      el: null,
    };
  },
  setup() {
    const baseUrlCms = 'https://cms.almarwan.com/';
    const latestArticleData = ref([]);
    const paginationVals = ref([]);
    const {
      app: { i18n },
    } = useContext();

    const getLangCode = () => (i18n.locale === 'ar' ? '&langcode=ar' : '');

    const options = {
      headers: {
        'Access-Control-Allow-Origin': true,
        'Content-Type': 'application/json',
      },
    };

    useFetch(async () => {
      const latestNewsResponse = await axios.get(
        `${baseUrlCms}api/blog/articles/latest?_format=json${getLangCode()}`,
        options
      );
      if (latestNewsResponse.data) {
        latestArticleData.value = latestNewsResponse.data.rows;
        paginationVals.value = latestNewsResponse.data.pager;
      }
    });

    return {
      latestArticleData,
      paginationVals,
    };
  },
  mounted() {
    window.addEventListener('mouseup', this.onMouseUp);
  },
  destroyed() {
    window.removeEventListener('mouseup', this.onMouseUp);
  },
  methods: {
    onMouseDown(ev) {
      this.cursorPos = [ev.pageX, ev.pageY];
      this.isDragging = true;

      window.addEventListener('mousemove', this.onMouseHold);
    },
    onMouseUp(ev) {
      setTimeout(() => {
        window.removeEventListener('mousemove', this.onMouseHold);
        this.isDragging = false;
      }, 300);
    },
    onMouseHold(ev) {
      ev.preventDefault();
      ev.stopPropagation();

      requestAnimationFrame(() => {
        const delta = [
          ev.pageX - this.cursorPos[0],
          ev.pageY - this.cursorPos[1],
        ];

        this.cursorPos = [ev.pageX, ev.pageY];

        if (!this.$refs.el) return;
        // @ts-ignore: Unreachable code error
        this.$refs.el.scrollBy({
          left: -delta[0],
          top: -delta[1],
        });
      });
    },
    strippedHtml(content) {
      const regex = /(<([^>]+)>)/gi;
      const txt = content.replace(regex, '');
      return txt.slice(0, 80) + '...';
    },
    getImgSrc(src) {
      return src.startsWith('/') ? src.slice(1) : src;
    },
    getArticleUrl(latestNews) {
      const viewNode: string = latestNews.view_node.replace('ar/', '');
      return '/news/' + latestNews.nid + '/' + viewNode.substring(1);
    },
  },
});
